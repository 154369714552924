import { ServiceByIdQuery, AttributeTypes_Enum } from '@organice/graphql'

export enum DateType {
  DATE = 'date',
  DATE_TIME = 'dateTime',
  DATE_TIME_RANGE = 'dateTimeRange',
  DATE_RANGE = 'dateRange',
  DATE_RANGE_TIME = 'dateRangeTime',
}

export type Service = NonNullable<ServiceByIdQuery['services_by_pk']>
export type ServiceModule = Service['serviceModules'][0]
export type ServiceModuleType = ServiceModule['moduleType']
export type AttributeGroup = ServiceModule['attributeGroups'][0]
export type Attribute = AttributeGroup['attributes'][0]
export type AttributeValueGroup = AttributeGroup['attributeValueGroups'][0]
export type AttributeValue = AttributeValueGroup['attributeValues'][0]
export type AttributeValueUser = AttributeValue['user']

export type AttributeValueProps = Optional<
  AttributeValueGroup['attributeValues'][0],
  'comments_aggregate'
>

export type AttributeComponentProps = {
  attributeValue: AttributeValueProps
  showDateSuggestions?: boolean
}

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export interface GenericAttributeValue<
  Value = unknown,
  Config extends Record<string, unknown> = Record<string, unknown>,
> extends Omit<AttributeValue, 'value' | 'attribute'> {
  value: { value: Value }
  attribute: GenericAttribute<Config>
}

export type TextAttributeValue = GenericAttributeValue<string>

export type NumberAttributeValue = GenericAttributeValue<
  number,
  { max?: number; allowDecimals?: boolean }
>

export type BooleanAttributeValue = GenericAttributeValue<boolean>

export type DateAttributeValue = GenericAttributeValue<
  (string | undefined)[] | (string | undefined)[][],
  { dateType: DateType; repeatable?: boolean }
>

export enum SelectTypes {
  Tags = 'tags',
}
type SelectOptions = string[] | { value: string; label: string }[]

export type SelectAttributeValue = GenericAttributeValue<
  (string | undefined)[] | (string | undefined)[][],
  { multiple?: boolean; type?: SelectTypes; options: SelectOptions }
>

export type RepeatableDateAttributeValue = GenericAttributeValue<
  (string | undefined)[][],
  { dateType: DateType; repeatable: true }
>

export type FileAttributeValue = GenericAttributeValue<
  null,
  { allowUpload?: boolean }
>

export interface UserAttributeValue<
  Config extends Record<string, unknown> = Record<string, unknown>,
> extends Omit<AttributeValue, 'attribute'> {
  attribute: GenericAttribute<Config>
  organisation?: { id: bigint; name: string }
}

export interface GenericAttributeValue<
  Value = unknown,
  Config extends Record<string, unknown> = Record<string, unknown>,
> extends Omit<AttributeValue, 'value' | 'attribute'> {
  value: { value: Value }
  attribute: GenericAttribute<Config>
}

export { AttributeTypes_Enum as AttributeType }

export function isAttributeValue<T extends GenericAttributeValue>(
  x: unknown,
  type: AttributeTypes_Enum
): x is T {
  if (!(x as GenericAttributeValue).attribute) {
    return false
  }

  return (x as GenericAttributeValue).attribute.attributeType === type
}

export interface GenericAttribute<Config extends Record<string, unknown>>
  extends Omit<Attribute, 'config'> {
  config: Config
}

export type UploadModuleConfig = {
  canPublish: boolean
  coOrder?: string
  pspElement?: string
  glAccount?: string
}
