// in this file, global variables of antd are overwritten
// also every variable that is defined in this file is injected into emotion's Theme
//
// antd: the module's hyphened is loaded within the webpack buil process to
// overwrite antdesign's less variables, see webpack.common.js for more info
//
// emotion: the module's camelCased is used as part of the theme provided to the ThemeProvider component by
// emotion, for more info see src/types.d.ts and src/index.tsx
// also, have a look at src/emotionTheme.ts for emotion specific globals

// add constants to recurring colors here
const ORGA_NICE_ORANGE = '#FF5014'
const ORGA_NICE_GREY = '#3E3D40'
const LIGHTER_GREY = '#F6F6F6'
const LIGHT_GREY = '#e3e4e4'
const DARK_GREY = '#87888A'
const LIGHT_ORANGE = '#ffd3c4'
const ORGA_NICE_GREY_2 = '#87888A'
const BORDER_COLOR = '#D9DADB'

// Media Breakpoints
const breakpoints = {
  screenXs: '480px',
  screenXsMin: '480px',
  screenSm: '576px',
  screenSmMin: '576px',
  screenMd: '768px',
  screenMdMin: '768px',
  screenLg: '992px',
  screenLgMin: '992px',
  screenXl: '1200px',
  screenXlMin: '1200px',
  screenXxl: '1600px',
  screenXxlMin: '1600px',
}

const mediaQueries = {
  screenXsMax: '@media (max-width: 480px)',
  screenXsMin: '@media (min-width: 480px)',
  screenSmMax: '@media (max-width: 576px)',
  screenSmMin: '@media (min-width: 576px)',
  screenMdMax: '@media (max-width: 768px)',
  screenMdMin: '@media (min-width: 768px)',
  screenLgMax: '@media (max-width: 992px)',
  screenLgMin: '@media (min-width: 992px)',
  screenXlMax: '@media (max-width: 1200px)',
  screenXlMin: '@media (min-width: 1200px)',
  screenXxlMax: '@media (max-width: 1600px)',
  screenXxlMin: '@media (min-width: 1600px)',
}

// Object.keys(breakpoints).reduce((res, key) => {
//   return { ...res, [key]: `@media (min-width: ${breakpoints[key]})` }
// }, {})

/* Default AntD Values */
const fontBaseSize = 14

const headingSizes = {
  heading1Size: '29px', //`${Math.ceil(fontBaseSize * 2.71)}px`,
  heading2Size: '25px', //`${Math.ceil(fontBaseSize * 2.14)}px`,
  heading3Size: '18px', //`${Math.ceil(fontBaseSize * 1.71)}px`,
  heading4Size: '16px', //`${Math.ceil(fontBaseSize * 1.42)}px`,
  heading5Size: '16px', //`${Math.ceil(fontBaseSize * 1.14)}px`,
}

// define your overwrites here by using a lower camel cased name of on of these attributes:
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
//
// if you want to add something that isn't meant to overwrite a antd variable, add it to the Theme
// type in src/types.d.ts
const camelCased = {
  ...breakpoints,
  ...headingSizes,
  primaryColor: ORGA_NICE_ORANGE,
  processingColor: ORGA_NICE_ORANGE,
  componentBackground: '#fff',
  borderRadiusBase: '2px',
  textColor: ORGA_NICE_GREY,
  linkColor: ORGA_NICE_GREY,
  linkHoverColor: ORGA_NICE_ORANGE,
  errorColor: '#FF4D4F',
  successColor: '#52c41a',
  formItemMarginBottom: '1rem',
  lightBackground: LIGHTER_GREY,
  layoutBodyBackground: '#fff',
  iconBaseColor: DARK_GREY,
  iconShadeColor: LIGHT_GREY,
  iconActiveBaseColor: ORGA_NICE_ORANGE,
  iconActiveShadeColor: LIGHT_ORANGE,
  borderColorBase: BORDER_COLOR,
  borderColorSplit: BORDER_COLOR,
  fontSizeSm: '12px',
  fontSizeRegular: '14px',
  textColorLight: ORGA_NICE_GREY_2,
  boxShadowBase: `0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05)`, // major shadow for layers
  mq: mediaQueries,
}

/**
 * This takes the camelCased object and transforms its keys into hyphened words, for example
 * {primaryColor: 'blue'} becomes {'primary-color': 'blue'}
 *
 * This is used to overwrite the antd variables which are named with hyphens, while the variables
 * for the emotion theme should be camelCased
 */
const hyphened = Object.entries(camelCased).reduce((acc, [key, value]) => {
  const transformedKey = key.replace(
    /[A-Z0-9]/g,
    char => `-${char.toLowerCase()}`
  )

  return {
    ...acc,
    [transformedKey]: value,
  }
}, {})

// eslint-disable-next-line no-undef
export { camelCased, hyphened }
