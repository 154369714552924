import React from 'react'
import {
  AttributeValueProps,
  isAttributeValue,
  TextAttributeValue,
  NumberAttributeValue,
  DateAttributeValue,
  AttributeType,
} from '../../../types/service'
import { Form } from 'antd'
import { EventServiceAttributeInput } from '../eventServiceAttributeInput'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { AttributeDisplay } from '../attributeDisplay'
import {
  CommentIcon,
  Small,
  LastUpdatedIcon,
  HasChangesMarker,
} from '@organice/atoms'

import {
  useServiceDisplayMode,
  ServiceDisplayMode,
  useDeeplink,
  useServiceModuleContext,
  useMeContext,
} from '@organice/contexts'
import { CommentTypes_Enum } from '@organice/contexts/comments/commentsContext'
import { useLocation, useParams } from 'react-router'
import {
  regexStringsDecorator,
  regexStringsDecoratorRenderer,
} from '@organice/utils/stringsDecorator'

import dayjs from 'dayjs'
import { SetMailNotificationCadenceDocument } from '@organice/graphql'

interface Params {
  attributeValueId?: string
  attributeId?: string
}

const ANIMATION_DURATION_SECONDS = 1.5

export const NewAttributeComponent: React.FC<
  AttributeValueProps
> = attributeValue => {
  const { hash } = useLocation()
  const { attributeValueId, attributeId } = useParams<Params>()
  const [commentOpen, setCommentOpen] = React.useState<number>()
  const [{ firstRender }, dispatchDeepLink] = useDeeplink()
  const [state] = useServiceDisplayMode()
  const serviceModuleContext = useServiceModuleContext()
  const serviceModule = React.useEffect(() => {
    //ServiceModuleContext
    const commentId = hash?.match(/comment\/[0-9]+\/([0-9]+)/)?.[1]

    setCommentOpen(
      commentId && Number(attributeValueId) === attributeValue.id
        ? Number(commentId)
        : undefined
    )
  }, [hash])

  const {
    state: { isGuest },
  } = useMeContext()

  const ref = React.useCallback(
    (node: HTMLDivElement) => {
      if (
        !firstRender ||
        !node ||
        (attributeValueId === undefined && attributeId === undefined)
      ) {
        return
      }

      const attributeValueNumberId = Number(attributeValueId)
      const attributeNumberId = Number(attributeId)

      if (
        attributeValueNumberId !== attributeValue.id &&
        attributeNumberId !== attributeValue.attribute.id
      ) {
        return
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).safari) {
        node.scrollIntoView(true)
      } else {
        node.scrollIntoView({ behavior: 'smooth' })
      }

      // if we dispatch before the animation went through, it will be interrupted
      window.setTimeout(() => {
        dispatchDeepLink({
          type: 'SET_FIRST_RENDER',
          payload: { firstRender: false },
        })
      }, ANIMATION_DURATION_SECONDS * 1000)

      // OPEN COMMENTS
    },
    [attributeValueId, attributeId]
  )

  const isExportMode = state.serviceDisplayMode === ServiceDisplayMode.EXPORT
  const isReadAccess =
    state.serviceDisplayMode === ServiceDisplayMode.READ_ACCESS

  const attributeVal = { ...attributeValue }
  const isEmptyText =
    (attributeVal.value?.value === undefined ||
      attributeVal.value?.value === '') &&
    isAttributeValue<TextAttributeValue>(attributeVal, AttributeType.Text)
  const isEmptyNumber =
    attributeVal.value?.value === undefined &&
    isAttributeValue<NumberAttributeValue>(attributeVal, AttributeType.Number)
  const isEmptyDate =
    (attributeVal.value?.value === undefined ||
      attributeVal.value?.value === '' ||
      (Array.isArray(attributeVal.value?.value) &&
        attributeVal.value?.value?.join().replace(/,/g, '').length === 0)) &&
    isAttributeValue<DateAttributeValue>(attributeVal, AttributeType.Date)
  const isEmptyCheckbox =
    (attributeVal.value?.value === undefined ||
      attributeVal.value?.value === '' ||
      attributeVal.value?.value === false) &&
    isAttributeValue<TextAttributeValue>(attributeVal, AttributeType.Boolean)

  if (
    isExportMode &&
    (isEmptyText || isEmptyNumber || isEmptyDate || isEmptyCheckbox)
  ) {
    return null
  }
  console.log('attributeValue', attributeValue)
  return (
    <Form.Item noStyle>
      <Container
        id={attributeValueId}
        ref={ref}
        animation={
          (firstRender &&
            attributeValueId !== undefined &&
            Number(attributeValueId) === attributeValue.id) ||
          (attributeId !== undefined &&
            Number(attributeId) === attributeValue.attribute.id)
        }
      >
        <ContainerLeft>
          <AttributeNameContainer>
            <HasChangesMarker
              preventUpdates
              hasChanges={serviceModuleContext.lastVisited?.isBefore(
                attributeValue.updatedAt
              )}
              placement="leftOutside"
            />
            {attributeValue.attribute.name}
          </AttributeNameContainer>
          <AttributeDescriptionContainer>
            <Small>
              {attributeValue.attribute.description &&
                regexStringsDecorator(
                  attributeValue.attribute.description,
                  regexStringsDecoratorRenderer
                )}
            </Small>
          </AttributeDescriptionContainer>
        </ContainerLeft>
        <ContainerRight>
          <Form.Item noStyle>
            {isExportMode || isReadAccess ? (
              <AttributeDisplay {...attributeValue}></AttributeDisplay>
            ) : (
              <EventServiceAttributeInput
                {...attributeValue}
              ></EventServiceAttributeInput>
            )}
          </Form.Item>
        </ContainerRight>
        {attributeValue.comments_aggregate ? (
          <AttributeLayoutComment>
            {!isGuest && !isExportMode && (
              <LastUpdatedIcon
                createdAt={attributeValue.createdAt}
                updatedAt={attributeValue.updatedAt}
                updatedBy={attributeValue.updatedBy_user || attributeValue.user}
              />
            )}
            {!isExportMode && (
              <CommentIcon
                open={commentOpen}
                count={attributeValue.comments_aggregate?.aggregate?.count}
                title={attributeValue.attribute.name}
                description={attributeValue.attribute.description}
                type={CommentTypes_Enum.AttribtueValueComment}
                id={attributeValue.id}
                guestCanRead={!!serviceModuleContext.guestCanRead}
              />
            )}
          </AttributeLayoutComment>
        ) : (
          <AttributeLayoutComment>
            {!isGuest && !isExportMode && attributeValue.id && (
              <LastUpdatedIcon
                createdAt={attributeValue.createdAt}
                updatedAt={attributeValue.updatedAt}
                updatedBy={attributeValue.updatedBy_user || attributeValue.user}
              />
            )}
          </AttributeLayoutComment>
        )}
      </Container>
    </Form.Item>
  )
}

const Container = styled.div<{ animation: boolean }>(
  ({ theme, animation }) => ({
    padding: '.625rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    animation: animation
      ? `${keyframes`
    from {
      background-color: ${theme.primaryColor};
    }
  `} ${ANIMATION_DURATION_SECONDS}s ease-out`
      : undefined,

    '&:nth-of-type(even)': {
      backgroundColor: theme.lightBackground,
    },
    '&:hover': {
      backgroundColor: theme.grey6,
    },
  })
)

const ContainerLeft = styled.div(({ theme }) => ({
  width: 'auto',
  maxWidth: 'calc(100% - 85px)', // 85px space for comment & info icons
  order: 1,
  marginBottom: 10,

  [theme.mq.screenLgMin]: {
    width: 'auto',
    flex: '1 1 0%',
    marginBottom: 0,
  },
}))

const AttributeNameContainer = styled.div({})

const AttributeDescriptionContainer = styled.div({})

const ContainerRight = styled.div(({ theme }) => ({
  order: 3,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  '.ant-picker, .ant-input-number': {
    width: '100%',
  },
  [theme.mq.screenLgMin]: {
    order: 2,
    width: 'auto',
    flex: '2 1 0%',
    justifyContent: 'flex-end',
  },
}))

const AttributeLayoutComment = styled.div(({ theme }) => ({
  order: 2,
  display: 'flex',
  padding: '0 0 0 0.625rem',
  alignItems: 'center',
  justifySelf: 'flex-end',
  alignSelf: 'flex-start',
  '> .ant-btn': {
    margin: '0 2px',
  },
  [theme.mq.screenLgMin]: {
    order: 3,
    // alignSelf: 'center',
  },
}))
